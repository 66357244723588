.principal-desc {
    --container-bg-color: #000;
    --left-bg-color: #000;
    /* --right-bg-color: rgba(43, 43, 43, 0.8); */
    --hover-width: 75%;
    --left-hover-width: 65%;
    --left-other-width: 35%;
    --other-width: 25%;
    --speed: 1000ms;
}

.description-seccion,
.description {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

.title-desc {
    font-size: 1.5rem;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.slogan-desc {
    font-size: 0.9rem;
    padding-top: 30% !important;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
    margin: 0 auto;
}

.image-contenedor {
    position: absolute;
    top: 60%;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(100);
    transition: all 0.3s ease-in-out;
}

.image-contenedor:hover {
    filter: none;
    transform: scale(1.1);
}

.image-desc {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.split {
    position: absolute;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.split.left {
    left: 0;
    background-size: cover;
}

.split.left:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--left-bg-color);
}

.split.right {
    right: 0;
}

.split.right .video-prinx {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.split.right:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
}

.split.left,
.split.right,
.split.right:before,
.split.left:before {
    transition: var(--speed) all ease-in-out;
}

.hover-left .left {
    width: var(--left-hover-width);
}

.hover-left .right {
    width: var(--left-other-width);
}

.hover-left .right:before {
    z-index: 2;
}

.hover-right .right {
    width: var(--hover-width);
}

.hover-right .left {
    width: var(--other-width);
}



@media screen and (min-width: 400px) and (max-width: 960px) {
    .title-desc {
        font-size: 2rem;
    }
}